<template>
  <div id="bookings-requests">
    <bookings-requests-list></bookings-requests-list>
  </div>
</template>

<script>
  import BookingsRequestsList from '../bookings/components/BookingsRequestsList'

  export default{
    name: 'BookingsRequestsView',
    components: {BookingsRequestsList}
  }
</script>
